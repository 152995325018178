@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Thin.eot');
  src: url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Roboto-Thin.woff2') format('woff2'),
       url('../fonts/Roboto-Thin.woff') format('woff'),
       url('../fonts/Roboto-Thin.ttf') format('truetype'),
       url('../fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab-Light.eot');
  src: url('../fonts/RobotoSlab-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/RobotoSlab-Light.woff2') format('woff2'),
       url('../fonts/RobotoSlab-Light.woff') format('woff'),
       url('../fonts/RobotoSlab-Light.ttf') format('truetype'),
       url('../fonts/RobotoSlab-Light.svg#RobotoSlab-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab-Regular.eot');
  src: url('../fonts/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/RobotoSlab-Regular.woff2') format('woff2'),
       url('../fonts/RobotoSlab-Regular.woff') format('woff'),
       url('../fonts/RobotoSlab-Regular.ttf') format('truetype'),
       url('../fonts/RobotoSlab-Regular.svg#RobotoSlab-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}
