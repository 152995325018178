.person {
  margin: 3em 0;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;

  .person-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }

  .person-id {
    text-align: center;
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));

    img {
      border-radius: 80% 50%;
      max-width: 150px;
      width: 80%;

      &.map {
        border-radius: 50% 80%;
      }
    }

    h2 {
      color: black;
      font-family: "Roboto Slab", serif;
      font-size: 1em;
      font-weight: 400;
      margin: 1em 0;
    }
  }

  .person-curriculum {
    width: -webkit-calc(40% - (#{$spacing-unit} / 2));
    width:         calc(40% - (#{$spacing-unit} / 2));
  }

  .person-intentions {
    width: -webkit-calc(40% - (#{$spacing-unit} / 2));
    width:         calc(40% - (#{$spacing-unit} / 2));
  }

  .person-interests {
    float: right;
    width: -webkit-calc(80% - (#{$spacing-unit} / 2));
    width:         calc(80% - (#{$spacing-unit} / 2));
  }

  @include media-query($on-palm) {
      .person-id,
      .person-curriculum,
      .person-intentions,
      .person-interests {
          width: -webkit-calc(100% - (#{$spacing-unit} / 2));
          width:         calc(100% - (#{$spacing-unit} / 2));
      }
  }

  &.legal .person-id img {
    border-radius: 0;
    max-width: 85px;
  }
}
