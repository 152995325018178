#home {

	h2 {
		display: none;
	}

	#vision {
		font-family: 'Roboto', sans-serif;
		font-size: 2em;
		line-height: 2;

		span {
			display: block;
		}

		@include media-query($on-palm) {
			font-size: 1.5em;

			span {
				display: inline;
			}
		}
	}

	#impact {
		line-height: 2;
		margin-bottom: 2em;

		a {
			border-radius: 3px;
			float: right;
			padding: 0 1em;

			&:hover {
				background-color: $brand-color;
				color: white;
				text-decoration: none;
			}
		}
	}

	.centered {
		text-align: center;
	}

	.offer-col-wrapper {
	    margin-left: -$spacing-unit / 2;
	    @extend %clearfix;
	}

	.offer-col {
		float: left;
		margin-bottom: $spacing-unit / 2;
		padding-left: $spacing-unit / 2;
		width: -webkit-calc(50% - (#{$spacing-unit} / 2));
		width:         calc(50% - (#{$spacing-unit} / 2));

		@include media-query($on-palm) {
			float: none;
			width: -webkit-calc(100% - (#{$spacing-unit} / 2));
			width:         calc(100% - (#{$spacing-unit} / 2));
		}
	}


	.intervention {
		float: left;
		margin-bottom: $spacing-unit / 2;
		padding-left: $spacing-unit / 2;
		width: -webkit-calc(33% - (#{$spacing-unit} / 2));
		width:         calc(33% - (#{$spacing-unit} / 2));

		h3 {
			text-align: center;
		}

		i {
			color: $brand-color;
			display: block;
			font-size: 80px;
			margin-bottom: 0.5em;
			text-align: center;
		}
	}

	.interventions-details {
		@extend %clearfix;
		@include media-query($on-palm) {
			.intervention {
				float: none;
				width: -webkit-calc(100% - (#{$spacing-unit} / 2));
				width:         calc(100% - (#{$spacing-unit} / 2));
			}
		}
	}
}
