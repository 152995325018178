.adhoc-col-wrapper {
	margin-top: $spacing-unit;
	margin-bottom: $spacing-unit;
	margin-left: -$spacing-unit / 2;
	@extend %clearfix;

	list-style: none;
}

.adhoc-col {
	float: left;
	margin-bottom: $spacing-unit / 2;
	padding-left: $spacing-unit / 2;
	width: -webkit-calc(50% - (#{$spacing-unit} / 2));
	width:         calc(50% - (#{$spacing-unit} / 2));

	@include media-query($on-palm) {
		float: none;
		width: -webkit-calc(100% - (#{$spacing-unit} / 2));
		width:         calc(100% - (#{$spacing-unit} / 2));
	}

	text-align: center;

	dt {
		background-color: $brand-color;
		border-radius: 3px;
		color: $background-color;
	}

	&.whole-width {
		width: -webkit-calc(100% - (#{$spacing-unit} / 2));
		width:         calc(100% - (#{$spacing-unit} / 2));
	}

	dd {
		margin: 0;
	}
}

.coaching-col-wrapper {
	margin-top: $spacing-unit;
	margin-bottom: $spacing-unit;
	margin-left: -$spacing-unit / 2;
	@extend %clearfix;

	list-style: none;
}

.coaching-col {
	float: left;
	margin-bottom: $spacing-unit / 2;
	padding-left: $spacing-unit / 2;
	width: -webkit-calc(50% - (#{$spacing-unit} / 2));
	width:         calc(50% - (#{$spacing-unit} / 2));

	@include media-query($on-palm) {
		float: none;
		width: -webkit-calc(100% - (#{$spacing-unit} / 2));
		width:         calc(100% - (#{$spacing-unit} / 2));
	}

	text-align: center;

	dt {
		background-color: $brand-color;
		border-radius: 3px;
		color: $background-color;
	}

	&.whole-width {
		width: -webkit-calc(100% - (#{$spacing-unit} / 2));
		width:         calc(100% - (#{$spacing-unit} / 2));
	}

	dd {
		margin: 0;
	}
}
