dl#version {
  text-align: right;
  dt, dd { display: inline-block; }

  dd {
    margin-left: 0;
    color: $brand-color;
  }

  dt {
    margin-left: 2em;
  }
}
