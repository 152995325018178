.commitment {
  margin: 3em 0;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;

  .commitment-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;

    h4 {
      margin: 0;
    }
  }

  .commitment-id {
    text-align: center;
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));

    .fa {
      color: $brand-color;
      font-size: 3em;
    }

    h2, h3 {
      color: black;
      font-family: "Roboto Slab", serif;
      font-size: 1em;
      font-weight: 400;
      margin: 1em 0;
    }
  }

  .commitment-left {
    width: -webkit-calc(40% - (#{$spacing-unit} / 2));
    width:         calc(40% - (#{$spacing-unit} / 2));
  }

  .commitment-right {
    width: -webkit-calc(40% - (#{$spacing-unit} / 2));
    width:         calc(40% - (#{$spacing-unit} / 2));
  }

  .commitment-bottom {
    float: right;
    width: -webkit-calc(80% - (#{$spacing-unit} / 2));
    width:         calc(80% - (#{$spacing-unit} / 2));
  }

  @include media-query($on-palm) {
      .commitment-id,
      .commitment-left,
      .commitment-right,
      .commitment-bottom {
          width: -webkit-calc(100% - (#{$spacing-unit} / 2));
          width:         calc(100% - (#{$spacing-unit} / 2));
      }
  }
}
