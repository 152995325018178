/**
 * Site header
 */
 $line-height: 80px;

.site-header {
    min-height: $line-height;

    // Positioning context for the mobile navigation icon
    position: relative;

    #quick-access-link {
      background-color: $brand-color;
      color: $background-color;
      font-size: 0.75em;
      margin: 0 0 1em;
      padding: 0 1em;
    	text-align: right;

    	li {
        /* Compass inline-block */
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        /* /Compass inline-block */

    		&+li:before
    		{
    			content: ' | ';
          padding: 0 1em;
    		}
    	}

    	a {
    		color: inherit;
    	}
    }
}

.site-title {
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    line-height: $line-height;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited,
    &:hover {
        color: $brand-color;
        text-decoration: none;
    }

    img {
      max-height: 40px;
      vertical-align: text-bottom;
    }
}

.site-nav {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    float: right;
    line-height: $line-height;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 47px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}



/**
 * Site footer
 */
#back-to-top {
  margin: 0;
  padding: 0;
  text-align: right;
}

.site-footer {
    padding: 0;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;

    &.highlight {
     background-color: $brand-color;
    }

    img {
      max-height: 16px;
      vertical-align: baseline;
    }
}

.contact-list,
.social-media-list,
.appendice-list {
    list-style: none;
    margin-left: 0;
}

footer p {
  margin: 0;
  padding: 0;
  text-align: center;

  &#licence {
    background-color: $brand-color;
    color: $background-color;
    font-size: 0.75em;

    img { vertical-align: text-top; }
    a { color: inherit }
  }
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.follow-up {
  text-align: center;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-top: $spacing-unit * 2;
        margin-bottom: $spacing-unit * 2;

        h2 {
          margin-bottom: 0;
          text-align: left;
        }
    }
}

.post-meta {
    font-family: 'Roboto Slab';
    font-size: $small-font-size;
    color: $grey-color;
    float: right;
    line-height: 4;
}

.rss-subscribe {
  margin-top: -3.7em;
  text-align: right;
}

.nota-bene {
  $icon-size: 1em;

  border-left: 1px dotted $grey-color-light;
  font-style: italic;
  padding-left: $icon-size;

  &::before {
    content: "\f05a";
    font-family: "FontAwesome";
    font-style: normal;
    font-size: $icon-size;
    margin-left: -$icon-size * 3;
    margin-right: $icon-size * 2;

    @include media-query($on-laptop) {
      margin-left: 0;
      margin-right: $icon-size / 2;
    }
  }
}

.update {
  @extend .nota-bene;

  &::before {
    content: "\f044";
  }
}

/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;
}

.footnotes {
  border-top: 1px $grey-color-light dotted;
  margin: 2em 0 0;
  padding: 0.5em;

  p {
    margin-bottom: 0;
  }
}
